import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home
  }, {
    path: '',
    component: () => import('../views/Dapp/MainPage.vue'),
    children: [{
      path: '/coin',
      component: () => import('../views/Dapp/Coin.vue')
    }, {
      path: '/nft',
      component: () => import('../views/Dapp/NFT.vue')
    },
    {
      path: '/xnft',
      component: () => import('../views/Dapp/XNFT.vue')
    },{
      path: '/mine',
      component: () => import('../views/Dapp/Mine.vue')
    }, {
        path: '/miner',
        component: () => import('../views/Dapp/Miner.vue')
    }, {
      path: '/Cooperation',
      component: () => import('../views/Dapp/Cooperation.vue')
    }, {
      path: '/withdraw',
      component: () => import('../views/Dapp/withdraw.vue')
    }, {
      path: '/earningsRecord',
      component: () => import('../views/Dapp/earningsRecord.vue')
    }, {
      path: '/invest',
      component: () => import('../views/Dapp/Invest.vue')
    },{
        path: '/sharerecord',
        component: () => import('../views/Dapp/sharerecord.vue')
      },
      {
      path: '/market',
      component: () => import('../views/Dapp/Market.vue')
    }, {
      path: '/my-reward',
      component: () => import('../views/Dapp/MyReward.vue')
    }, {
        path: '/message',
        component: () => import('../views/Dapp/Message.vue')
      }

      ]
  }
  , {
    path: '',
    component: () => import('../views/Fomo/MainPage.vue'),
    children: [{
      path: '/fomo',
      component: () => import('../views/Fomo/fomo.vue')
      },
      {
        path: '/USDswap',
        component: () => import('../views/Fomo/USDswap.vue')
      },
      {
        path: '/wfswap',
        component: () => import('../views/Fomo/wfswap.vue')
      },
      {
        path: '/cwfswap',
        component: () => import('../views/Fomo/cwfswap.vue')
      },
      {
        path: '/myreward',
        component: () => import('../views/Fomo/myreward.vue')
      },
      {
        path: '/team',
        component: () => import('../views/Fomo/team.vue')
      },
      {
        path: '/buyminer',
        component: () => import('../views/Fomo/buyminer.vue')
      },
      {
        path: '/node',
        component: () => import('../views/Fomo/node.vue')
      }
      ,
      {
        path: '/fomomessage',
        component: () => import('../views/Fomo/Message.vue')
      },
      {
        path: '/swaphelper',
        component: () => import('../views/Fomo/swaphelper.vue')
      }
    ]
  }
  , {
    path: '',
    component: () => import('../views/SuperFomo/MainPage.vue'),
    children: [{
      path: '/sfomo',
      component: () => import('../views/SuperFomo/fomo.vue')
    },
      {
        path: '/sUSDswap',
        component: () => import('../views/SuperFomo/USDswap.vue')
      },
      {
        path: '/swfswap',
        component: () => import('../views/SuperFomo/wfswap.vue')
      },
      {
        path: '/scwfswap',
        component: () => import('../views/SuperFomo/cwfswap.vue')
      },
      {
        path: '/smyreward',
        component: () => import('../views/SuperFomo/myreward.vue')
      },
      {
        path: '/steam',
        component: () => import('../views/SuperFomo/team.vue')
      },
      {
        path: '/sbuyminer',
        component: () => import('../views/SuperFomo/buyminer.vue')
      },
      {
        path: '/snode',
        component: () => import('../views/SuperFomo/node.vue')
      }
      ,
      {
        path: '/sfomomessage',
        component: () => import('../views/SuperFomo/Message.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
